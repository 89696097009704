import React, { useEffect, useState } from "react";
import './ProductDetail.css';
import cubo from '../images/cubo.jpeg';
import cubik1 from '../images/C4T2UA.jpg';
import cubik2 from '../images/C4T2UA.jpg';
import cubik3 from '../images/C4T2UA.jpg';
import cubik4 from '../images/C4T2UA.jpg';
import { useParams } from "react-router-dom";

import C4T2UG from '../images/C4T2UG.jpg';
import C4T2UB from '../images/C4T2UB.jpg';
import C4T2UV from '../images/C4T2UV.jpg';
import C4T2UA from '../images/C4T2UA.jpg';
import CC4T2UG from '../images/CC4T2UG.jpg';
import CC4T2UB from '../images/CC4T2UB.jpg';
import CC4T2UV from '../images/CC4T2UV.jpg';
import CC4T2UA from '../images/CC4T2UA.jpg';
import C5TG from '../images/C5TG.jpg';
import C5TB from '../images/C5TB.jpg';
import C5TV from '../images/C5TV.jpg';
import C5TA from '../images/C5TA.jpg';
import P7TG from '../images/P7TG.jpg';
import P7TB from '../images/P7TB.jpg';
import P7TV from '../images/P7TV.jpg';
import P7TA from '../images/P7TA.jpg';
import P6T2UG from '../images/P6T2UG.jpg';
import P6T2UB from '../images/P6T2UB.jpg';
//import P6T2UV from '../images/P6T2UV.jpg';
import P6T2UA from '../images/P6T2UA.jpg';
import CE from '../images/CE.jpg';
import CEG from '../images/CEG.jpg';
import CEB from '../images/CEB.jpg';
//import CEV from '../images/CEV.jpg';
//import CEA from '../images/CEA.jpg';
import CCE from '../images/CCE.jpg';
import CCEG from '../images/CCEG.jpg';
import CCDG from '../images/CCDG.jpg';
import CCEB from '../images/CCEB.jpg';
import CCDB from '../images/CCDB.jpg';
import CCEV from '../images/CCEV.jpg';
import CCDV from '../images/CCDV.jpg';
import CCEA from '../images/CCEA.jpg';
import CCDA from '../images/CCDA.jpg';
import P6T2UGF from '../images/P6T2UGF.jpg';
import P6T2UBF from '../images/P6T2UBF.jpg';
import P6T2UAF from '../images/P6T2UAF.jpg';
//import P6T2UVF from '../images/P6T2UVF.jpg';
import PEF from '../images/PEF.jpg';
import PED from '../images/PED.jpg';
import PD from '../images/PD.jpg';                    
import P7TGF from '../images/P7TGF.jpg';                    
import P7TAF from '../images/P7TAF.jpg';
import P7TVF from '../images/P7TVF.jpg';
import P7TBF from '../images/P7TBF.jpg';
import PN from '../images/normalizado.png';
import PL400G from '../images/PL400G.png';
import PL400G2 from '../images/PL400G2.png';
import PL400G3 from '../images/PL400G3.png';
import PL400G4 from '../images/PL400G4.png';
import PL410G from '../images/PL410G.png';
import PL410G2 from '../images/PL410G2.png';
import PL410G3 from '../images/PL410G3.png';
import PL410G4 from '../images/PL410G4.png';


function getDetail(id) {
  const myPromise = new Promise((resolve, reject) => {
      const vuelosList = [
        {
          id: 1,
          name: 'CUBIK 4 TOMAS + 2 USB 3 PERNOS',
          color: 'GRIS',
          href: '#',
          price: '$---',
          imageSrc: C4T2UG,
          det1:C5TG,
          det2:CEG,
          det3:CE,
          det4:C4T2UG,
          imageAlt: 'CUBIK 4 TOMAS 2 USB 3 PERNOS',
          modelo: 'CUBIK +',
          voltaje: '220 V',
          max: '10 A',
          cusb: '2',
          usb: '5 V 3.0 CARGA RAPIDA',
          tc: '4',   
          grupo: 1,       
        },
        {
          id: 2,
          name: 'CUBIK 4 TOMAS + 2 USB 3 PERNOS',
          color: 'BORDO',
          href: '#',
          price: '$---',
          imageSrc: C4T2UB,
          imageAlt: 'CUBIK 4 TOMAS 2 USB 3 PERNOS',
          det1:C5TB,
          det2:CEG,
          det3:CE,
          det4:C4T2UB,
          modelo: 'CUBIK +',
          voltaje: '220 V',
          max: '10 A',
          cusb: '2',
          usb: '5 V 3.0 CARGA RAPIDA',
          tc: '4',  
          grupo: 1, 
        },
        {
          id: 3,
          name: 'CUBIK 4 TOMAS + 2 USB 3 PERNOS',
          color: 'VERDE',
          href: '#',
          price: '$---',
          imageSrc: C4T2UV,
          imageAlt: 'CUBIK 4 TOMAS 2 USB 3 PERNOS',
          det1:C5TV,
          det2:CEG,
          det3:CE,
          det4:C4T2UV,
          modelo: 'CUBIK +',
          voltaje: '220 V',
          max: '10 A',
          cusb: '2',
          usb: '5 V 3.0 CARGA RAPIDA',
          tc: '4',
          grupo: 1,
        },
        {
          id: 4,
          name: 'CUBIK 4 TOMAS + 2 USB 3 PERNOS',
          color: 'AZUL',
          href: '#',
          price: '$---',
          imageSrc: C4T2UA,
          imageAlt: 'CUBIK 4 TOMAS 2 USB 3 PERNOS',
          det1:C5TA,
          det2:CEG,
          det3:CE,
          det4:C4T2UA,
          modelo: 'CUBIK +',
          voltaje: '220 V',
          max: '10 A',
          cusb: '2',
          usb: '5 V 3.0 CARGA RAPIDA',
          tc: '4',
          grupo: 1,
        },
        {
          id: 5,
          name: 'CUBIK 5 TOMAS 3 PERNOS',
          color: 'GRIS',
          href: '#',
          price: '$---',
          imageSrc: C5TG,
          imageAlt: 'CUBIK 5 TOMAS 3 PERNOS',
          det1:C5TG,
          det2:CEG,
          det3:CE,
          det4:C5TG,
          modelo: 'CUBIK +',
          voltaje: '220 V',
          max: '10 A',
          cusb: '-',
          usb: 'NO POSEE',
          tc: '5',
          grupo: 1,
        },
        {
          id: 6,
          name: 'CUBIK 5 TOMAS 3 PERNOS',
          color: 'BORDO',
          href: '#',
          price: '$---',
          imageSrc: C5TB,
          imageAlt: 'CUBIK 5 TOMAS 3 PERNOS',
          det1:C5TB,
          det2:CEB,
          det3:CE,
          det4:C5TB,
          modelo: 'CUBIK +',
          voltaje: '220 V',
          max: '10 A',
          cusb: '-',
          usb: 'NO POSEE',
          tc: '5',
          grupo: 1,
        },
        {
          id: 7,
          name: 'CUBIK 5 TOMAS 3 PERNOS',
          color: 'VERDE',
          href: '#',
          price: '$---',
          imageSrc: C5TV,
          imageAlt: 'CUBIK 5 TOMAS 3 PERNOS',
          det1:C5TV,
          det2:CEB,
          det3:CE,
          det4:C5TV,
          modelo: 'CUBIK +',
          voltaje: '220 V',
          max: '10 A',
          cusb: '-',
          usb: 'NO POSEE',
          tc: '5',
          grupo: 1,
        },
        {
          id: 8,
          name: 'CUBIK 5 TOMAS 3 PERNOS',
          color: 'AZUL',
          href: '#',
          price: '$---',
          imageSrc: C5TA,
          imageAlt: 'CUBIK 5 TOMAS 3 PERNOS',
          det1:C5TA,
          det2:CEB,
          det3:CE,
          det4:C5TA,
          modelo: 'CUBIK +',
          voltaje: '220 V',
          max: '10 A',
          cusb: '-',
          usb: 'NO POSEE',
          tc: '5',
          grupo: 1,
        },
        {
          id: 9,
          name: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
          color: 'GRIS',
          href: '#',
          price: '$---',
          imageSrc: CC4T2UG,
          imageAlt: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
          det1:CC4T2UG,
          det2:CCEG,
          det3:CCE,
          det4:CCDG,
          modelo: 'CUBIK',
          voltaje: '220 V',
          max: '10 A',
          cusb: '2',
          usb: '5 V 3.0 CARGA RAPIDA',
          tc: '4',
          grupo: 1,
        },  
        {
          id: 10,
          name: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
          color: 'BORDO',
          href: '#',
          price: '$---',
          imageSrc: CC4T2UB,
          imageAlt: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
          det1:CC4T2UB,
          det2:CCEB,
          det3:CCE,
          det4:CCDB,
          modelo: 'CUBIK',
          voltaje: '220 V',
          max: '10 A',
          cusb: '2',
          usb: '5 V 3.0 CARGA RAPIDA',
          tc: '4',
          grupo: 1,
        },
        {
          id: 11,
          name: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
          color: 'VERDE',
          href: '#',
          price: '$---',
          imageSrc: CC4T2UV,
          imageAlt: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
          det1:CC4T2UV,
          det2:CCEV,
          det3:CCE,
          det4:CCDV,
          modelo: 'CUBIK',
          voltaje: '220 V',
          max: '10 A',
          cusb: '2',
          usb: '5 V 3.0 CARGA RAPIDA',
          tc: '4',
          grupo: 1,
        },
        {
          id: 12,
          name: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
          color: 'AZUL',
          href: '#',
          price: '$---',
          imageSrc: CC4T2UA,
          imageAlt: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
          det1:CC4T2UA,
          det2:CCEA,
          det3:CCE,
          det4:CCDA,
          modelo: 'CUBIK',
          voltaje: '220 V',
          max: '10 A',
          cusb: '2',
          usb: '5 V 3.0 CARGA RAPIDA',
          tc: '4',
          grupo: 1,
        },
        {
          id: 13,
          name: 'PROLONGADOR 6 TOMAS + 2 USB',
          color: 'GRIS',
          href: '#',
          price: '$---',
          imageSrc: P6T2UG,
          imageAlt: 'PROLONGADOR 6 TOMAS + 2 USB',
          det1:P6T2UGF,
          det2:PEF,
          det3:PED,
          det4:PD,
          modelo: 'REC 6',
          voltaje: '220 V',
          max: '10 A',
          cusb: '2',
          usb: '5 V 3.0 CARGA RAPIDA',
          tc: '6',
          grupo: 1,
        },  
        {
          id: 14,
          name: 'PROLONGADOR 6 TOMAS + 2 USB',
          color: 'BORDO',
          href: '#',
          price: '$---',
          imageSrc: P6T2UB,
          imageAlt: 'PROLONGADOR 6 TOMAS + 2 USB',
          det1:P6T2UBF,
          det2:PEF,
          det3:PED,
          det4:PD,
          modelo: 'REC 6',
          voltaje: '220 V',
          max: '10 A',
          cusb: '2',
          usb: '5 V 3.0 CARGA RAPIDA',
          tc: '6',
          grupo: 1,
        },  
        {
          id: 15,
          name: 'PROLONGADOR 6 TOMAS + 2 USB',
          color: 'VERDE',
          href: '#',
          price: '$---',
          imageSrc: P7TV,
          imageAlt: 'PROLONGADOR 6 TOMAS + 2 USB',
          det1:P7TV,
          det2:PEF,
          det3:PED,
          det4:PD,
          modelo: 'REC 6',
          voltaje: '220 V',
          max: '10 A',
          cusb: '2',
          usb: '5 V 3.0 CARGA RAPIDA',
          tc: '6',
          grupo: 1,
        },  
        {
          id: 16,
          name: 'PROLONGADOR 6 TOMAS + 2 USB',
          color: 'AZUL',
          href: '#',
          price: '$---',
          imageSrc: P6T2UA,
          imageAlt: 'PROLONGADOR 6 TOMAS + 2 USB',
          det1:P6T2UAF,
          det2:PEF,
          det3:PED,
          det4:PD,
          modelo: 'REC 6',
          voltaje: '220 V',
          max: '10 A',
          cusb: '2',
          usb: '5 V 3.0 CARGA RAPIDA',
          tc: '6',
          grupo: 1,
        },  
        {
          id: 17,
          name: 'PROLONGADOR 7 TOMAS',
          color: 'GRIS',
          href: '#',
          price: '$---',
          imageSrc: P7TG,
          imageAlt: 'PROLONGADOR 7 TOMAS',
          det1:P7TGF,
          det2:PEF,
          det3:PED,
          det4:PD,
          modelo: 'REC 7',
          voltaje: '220 V',
          max: '10 A',
          cusb: '-',
          usb: 'NO POSEE',
          tc: '7',
          grupo: 1,
        },
        {
          id: 18,
          name: 'PROLONGADOR 7 TOMAS',
          color: 'BORDO',
          href: '#',
          price: '$---',
          imageSrc: P7TB,
          imageAlt: 'PROLONGADOR 7 TOMAS',
          det1:P7TBF,
          det2:PEF,
          det3:PED,
          det4:PD,
          modelo: 'REC 7',
          voltaje: '220 V',
          max: '10 A',
          cusb: '-',
          usb: 'NO POSEE',
          tc: '7',
          grupo: 1,
        },
        {
          id: 19,
          name: 'PROLONGADOR 7 TOMAS',
          color: 'VERDE',
          href: '#',
          price: '$---',
          imageSrc: P7TV,
          imageAlt: 'PROLONGADOR 7 TOMAS',
          det1:P7TVF,
          det2:PEF,
          det3:PED,
          det4:PD,
          modelo: 'REC 7',
          voltaje: '220 V',
          max: '10 A',
          cusb: '-',
          usb: 'NO POSEE',
          tc: '7',
          grupo: 1,
        },
        {
          id: 20,
          name: 'PROLONGADOR 7 TOMAS',
          color: 'AZUL',
          href: '#',
          price: '$---',
          imageSrc: P7TA,
          imageAlt: 'PROLONGADOR 7 TOMAS',
          det1:P7TAF,
          det2:PEF,
          det3:PED,
          det4:PD,
          modelo: 'REC 7',
          voltaje: '220 V',
          max: '10 A',
          cusb: '-',
          usb: 'NO POSEE',
          tc: '7',
          grupo: 1,
        },
        {
          id: 21,
          name: 'PORTALAMPARA NORMALIZADO CON CHICOTE',
          color: 'NEGRO',          
          price: '$---',
          imageSrc: PN,
          imageAlt: 'PORTALAMPARA NORMALIZADO CON CHICOTE',
          det1:PN,  
          det2:"",    
          det3:"",
          det4:"",        
          modelo: 'PL200N',
          voltaje: '220 V',          
          grupo: 2,
          material: "POLIPROPILENO"
        },
        {
          id: 22,
          name: 'PORTALAMPARA PARA GUIRNALDA PL400G',
          color: 'NEGRO - BLANCO',
          href: '#',
          price: '$---',
          imageSrc: PL400G,
          imageAlt: 'PORTALAMPARA PARA GUIRNALDA PL400G',
          det1:PL400G,
          det2:PL400G2,    
          det3:PL400G3,
          det4:PL400G4,      
          modelo: 'PL400G',
          voltaje: '220 V',          
          grupo: 2,
          material: "POLIPROPILENO"
        },
        {
          id: 23,
          name: 'PORTALAMPARA PARA GUIRNALDA PL410G',
          color: 'NEGRO - BLANCO',
          href: '#',
          price: '$---',
          imageSrc: PL410G,
          imageAlt: 'PORTALAMPARA PARA GUIRNALDA PL410G',
          det1:PL410G,
          det2:PL410G2,    
          det3:PL410G3,
          det4:PL410G4,      
          modelo: 'PL410G',
          voltaje: '220 V',          
          grupo: 2,
          material: "POLIPROPILENO"
        },
      ];
      const vuelo = id ? vuelosList.filter(v => v.id === parseInt(id)) : vuelosList;  
      setTimeout(() => {
          console.log("vuelo "+vuelo[0])
          resolve(vuelo[0]);
    }, 1000);
  });
  return myPromise;
}

const ProductDetail = () => {
  
  const {id} = useParams();
  const [detalle, setDetalle] = useState({});
  
  useEffect(() => {       
        getDetail(id)              
            .then(res => {                
                setDetalle({...res,id: res.id});                
            })
  }, [id]);

  return (
    <div className="bg-gray-300">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-8 sm:px-6 sm:py-12 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{detalle.name}</h2>          
          {(detalle.grupo == 1 ? 
                        
          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
            
              <div key={detalle.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">MODELO</dt>
                <dd className="mt-2 text-sm text-gray-500">{detalle.modelo}</dd>
              </div>
              <div key={detalle.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">CANTIDAD DE TOMA CORRIENTE</dt>
                <dd className="mt-2 text-sm text-gray-500">{detalle.tc}</dd>
              </div>
              <div key={detalle.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">VOLTAJE</dt>
                <dd className="mt-2 text-sm text-gray-500">{detalle.voltaje}</dd>
              </div>
              <div key={detalle.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">USB</dt>
                <dd className="mt-2 text-sm text-gray-500">{detalle.usb}</dd>                
              </div>
              <div key={detalle.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">CARGA MAXIMA</dt>
                <dd className="mt-2 text-sm text-gray-500">{detalle.max}</dd>
              </div>                          
              <div key={detalle.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">CANTIDAD DE PUERTOS USB</dt>
                <dd className="mt-2 text-sm text-gray-500">{detalle.cusb}</dd>
              </div>


          </dl>
          :
          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
              <div key={detalle.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">MODELO</dt>
                <dd className="mt-2 text-sm text-gray-500">{detalle.modelo}</dd>
              </div>
              <div key={detalle.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">MATERIAL</dt>
                <dd className="mt-2 text-sm text-gray-500">{detalle.material}</dd>
              </div>
              <div key={detalle.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">VOLTAJE</dt>
                <dd className="mt-2 text-sm text-gray-500">{detalle.voltaje}</dd>
              </div>
              <div key={detalle.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">COLORES</dt>
                <dd className="mt-2 text-sm text-gray-500">{detalle.color}</dd>                
              </div>              
          </dl>
          )}
        </div>         
        <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">     
          {(detalle.det1 ?     
          <img
            src={detalle.det1}
            alt={detalle.imageAlt}
            className="rounded-lg bg-gray-100"
          />:""
          )}
          {(detalle.det2 ?
          <img
            src={detalle.det2}
            alt={detalle.imageAlt}
            className="rounded-lg bg-gray-100"
          />:"")}
          {(detalle.det3 ?
          <img
            src={detalle.det3}
            alt={detalle.imageAlt}
            className="rounded-lg bg-gray-100"
            />:"")}
          {(detalle.det4 ?
          <img
            src={detalle.det4}
            alt={detalle.imageAlt}
            className="rounded-lg bg-gray-100"
          />:"")}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
