import React from "react";
import enled from '../images/enled.png';
import Fondo from "../components/Fondo.jsx";
import { FaWhatsapp } from 'react-icons/fa';

const Contact = () =>{
    const openWhatsApp = () => {
        window.open("https://api.whatsapp.com/send?phone=5491140483189", '_blank');
      }
    return(
        <section class="h-screen p-12 bg-gray-300">
      <div class="max-w-7xl mx-auto text-center">
        <img src={enled} alt="Site under construction" class="mx-auto"></img> 
        <div class="flex flex-col justify-center items-center p-12">
          <p class="text-4xl p-4">COMUNICATE CON NOSOTROS</p>
          <p class="text-4xl p-4">info@enled.com.ar</p>
          <button onClick={openWhatsApp}>            
            <FaWhatsapp size={32} color="#25d366" />              
          </button>          
        </div>
      </div>
    </section>
        
    )
}

export default Contact;

{/* <div className='h-screen bg-[url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsLsvmbBPRWI1u7l8S3radV-i3rPgBHtEx1Q&usqp=CAU")] bg-cover'>
            <div className="h-full flex justify-center items-center">
                <img src={prueba} alt='logo enled'></img>
            </div>
        </div> */}

    /*     <div className='h-screen'>
            <div>
                <Fondo></Fondo>
                <div className="h-full flex justify-center items-center">
                    <img src={prueba} alt='logo enled'></img>
                </div>
            </div>
        </div> */

        /*<Fondo>
            
            </Fondo> 
            <div className="absolute">
                <img src={prueba} alt='logo enled'></img>  
                               
            </div>*/