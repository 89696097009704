import './App.css';
import NavBar from './components/Navbar';
import Body from './pages/Body';
import Footer from './pages/Footer';
import Hero from './pages/Hero';
import enled from '../src/images/enled.png';
import { FaWhatsapp } from 'react-icons/fa';
import Products from './pages/Products';
import {Route,Routes} from 'react-router-dom';

import React, {useState, useEffect} from 'react';
import ProductGallery from './pages/ProductGallery';

import ProductDetail from './pages/ProductDetail';
import cuboConCable from '../src/images/CC5TA.jpg';
import cuboConCable2 from '../src/images/CC5TB.jpg';
import zapa from '../src/images/P7TA.jpg';
import cubik from '../src/images/C4T2UV.jpg';
import Contact from './pages/Contact';

import C4T2UG from '../src/images/C4T2UG.jpg';
import C4T2UB from '../src/images/C4T2UB.jpg';
import C4T2UV from '../src/images/C4T2UV.jpg';
import C4T2UA from '../src/images/C4T2UA.jpg';
import CC4T2UG from '../src/images/CC4T2UG.jpg';
import CC4T2UB from '../src/images/CC4T2UB.jpg';
import CC4T2UV from '../src/images/CC4T2UV.jpg';
import CC4T2UA from '../src/images/CC4T2UA.jpg';
import C5TG from '../src/images/C5TG.jpg';
import C5TB from '../src/images/C5TB.jpg';
import C5TV from '../src/images/C5TV.jpg';
import C5TA from '../src/images/C5TA.jpg';
import P7TG from '../src/images/P7TG.jpg';
import P7TB from '../src/images/P7TB.jpg';
import P7TV from '../src/images/P7TV.jpg';
import P7TA from '../src/images/P7TA.jpg';
import P6T2UG from '../src/images/P6T2UG.jpg';
import P6T2UB from '../src/images/P6T2UB.jpg';
//import P6T2UV from '../src/images/P6T2UV.jpg';
import P6T2UA from '../src/images/P6T2UA.jpg';
import PN from '../src/images/normalizado.png';
import PL400G from '../src/images/PL400G.png';
import PL410G from '../src/images/PL410G.png';

function getDetail() {
  const myPromise = new Promise((resolve, reject) => {
      const vuelosList = [
        {
          id: 1,
          name: 'CUBIK 4 TOMAS + 2 USB 3 PERNOS',
          color: 'GRIS',
          href: '#',
          price: '$---',
          imageSrc: C4T2UG,
          imageDet:{
            det1:C4T2UA,
            det2:C4T2UV,
            det3:C4T2UB,
            det4:C4T2UG,
          },
          imageAlt: 'CUBIK 4 TOMAS 2 USB 3 PERNOS',
        },
        {
          id: 2,
          name: 'CUBIK 4 TOMAS + 2 USB 3 PERNOS',
          color: 'BORDO',
          href: '#',
          price: '$---',
          imageSrc: C4T2UB,
          imageAlt: 'CUBIK 4 TOMAS 2 USB 3 PERNOS',
        },
        {
          id: 3,
          name: 'CUBIK 4 TOMAS + 2 USB 3 PERNOS',
          color: 'VERDE',
          href: '#',
          price: '$---',
          imageSrc: C4T2UV,
          imageAlt: 'CUBIK 4 TOMAS 2 USB 3 PERNOS',
        },
        {
          id: 4,
          name: 'CUBIK 4 TOMAS + 2 USB 3 PERNOS',
          color: 'AZUL',
          href: '#',
          price: '$---',
          imageSrc: C4T2UA,
          imageAlt: 'CUBIK 4 TOMAS 2 USB 3 PERNOS',
        },
        {
          id: 5,
          name: 'CUBIK 5 TOMAS 3 PERNOS',
          color: 'GRIS',
          href: '#',
          price: '$---',
          imageSrc: C5TG,
          imageAlt: 'CUBIK 5 TOMAS 3 PERNOS',
        },
        {
          id: 6,
          name: 'CUBIK 5 TOMAS 3 PERNOS',
          color: 'BORDO',
          href: '#',
          price: '$---',
          imageSrc: C5TB,
          imageAlt: 'CUBIK 5 TOMAS 3 PERNOS',
        },
        {
          id: 7,
          name: 'CUBIK 5 TOMAS 3 PERNOS',
          color: 'VERDE',
          href: '#',
          price: '$---',
          imageSrc: C5TV,
          imageAlt: 'CUBIK 5 TOMAS 3 PERNOS',
        },
        {
          id: 8,
          name: 'CUBIK 5 TOMAS 3 PERNOS',
          color: 'AZUL',
          href: '#',
          price: '$---',
          imageSrc: C5TA,
          imageAlt: 'CUBIK 5 TOMAS 3 PERNOS',
        },
        {
          id: 9,
          name: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
          color: 'GRIS',
          href: '#',
          price: '$---',
          imageSrc: CC4T2UG,
          imageAlt: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
        },  
        {
          id: 10,
          name: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
          color: 'BORDO',
          href: '#',
          price: '$---',
          imageSrc: CC4T2UB,
          imageAlt: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
        },
        {
          id: 11,
          name: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
          color: 'VERDE',
          href: '#',
          price: '$---',
          imageSrc: CC4T2UV,
          imageAlt: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
        },
        {
          id: 12,
          name: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
          color: 'AZUL',
          href: '#',
          price: '$---',
          imageSrc: CC4T2UA,
          imageAlt: 'CUBIK CON CABLE 4 TOMAS + 2 USB',
        },
        {
          id: 13,
          name: 'PROLONGADOR 6 TOMAS + 2 USB',
          color: 'GRIS',
          href: '#',
          price: '$---',
          imageSrc: P6T2UG,
          imageAlt: 'PROLONGADOR 6 TOMAS + 2 USB',
        },  
        {
          id: 14,
          name: 'PROLONGADOR 6 TOMAS + 2 USB',
          color: 'BORDO',
          href: '#',
          price: '$---',
          imageSrc: P6T2UB,
          imageAlt: 'PROLONGADOR 6 TOMAS + 2 USB',
        },  
        {
          id: 15,
          name: 'PROLONGADOR 6 TOMAS + 2 USB',
          color: 'VERDE',
          href: '#',
          price: '$---',
          imageSrc: P7TV,
          imageAlt: 'PROLONGADOR 6 TOMAS + 2 USB',
        },  
        {
          id: 16,
          name: 'PROLONGADOR 6 TOMAS + 2 USB',
          color: 'AZUL',
          href: '#',
          price: '$---',
          imageSrc: P6T2UA,
          imageAlt: 'PROLONGADOR 6 TOMAS + 2 USB',
        },  
        {
          id: 17,
          name: 'PROLONGADOR 7 TOMAS',
          color: 'GRIS',
          href: '#',
          price: '$---',
          imageSrc: P7TG,
          imageAlt: 'PROLONGADOR 7 TOMAS',
        },
        {
          id: 18,
          name: 'PROLONGADOR 7 TOMAS',
          color: 'BORDO',
          href: '#',
          price: '$---',
          imageSrc: P7TB,
          imageAlt: 'PROLONGADOR 7 TOMAS',
        },
        {
          id: 19,
          name: 'PROLONGADOR 7 TOMAS',
          color: 'VERDE',
          href: '#',
          price: '$---',
          imageSrc: P7TV,
          imageAlt: 'PROLONGADOR 7 TOMAS',
        },
        {
          id: 20,
          name: 'PROLONGADOR 7 TOMAS',
          color: 'AZUL',
          href: '#',
          price: '$---',
          imageSrc: P7TA,
          imageAlt: 'PROLONGADOR 7 TOMAS',
        },
        {
          id: 21,
          name: 'PORTALAMPARA NORMALIZADO CON CHICOTE',
          color: 'AZUL',
          href: '#',
          price: '$---',
          imageSrc: PN,
          imageAlt: 'PORTALAMPARA NORMALIZADO CON CHICOTE',
        },
        {
          id: 22,
          name: 'PORTALAMPARA PARA GUIRNALDA PL400G',
          color: 'AZUL',
          href: '#',
          price: '$---',
          imageSrc: PL400G,
          imageAlt: 'PORTALAMPARA PARA GUIRNALDA PL400G',
        },
        {
          id: 23,
          name: 'PORTALAMPARA PARA GUIRNALDA PL410G',
          color: 'AZUL',
          href: '#',
          price: '$---',
          imageSrc: PL410G,
          imageAlt: 'PORTALAMPARA PARA GUIRNALDA PL410G',
        },
      ];
      const vuelo = vuelosList;  
      setTimeout(() => {          
          resolve(vuelo);
    }, 1000);
  });
  return myPromise;
}

function App() {

  const [products, setDetalle] = useState([]);
  
  useEffect(() => {   
        console.log("entro");    
        getDetail()
            .then(res => {                
                setDetalle([...res,]);                
                console.log("Array.isArray(products)"+Array.isArray(products))
            })
  },[]);

  return (
    <>
      
      <NavBar></NavBar>
      <Routes>
          <Route exact path='/' element={<Hero></Hero>} />
          <Route path='/products' element={<ProductGallery products={products} />} />
          <Route path="/productDetail/:id" element={<ProductDetail></ProductDetail>} />
          <Route path="/contact" element={<Contact></Contact>} />
      </Routes>
    </>
  );
}

export default App;

