import React, {useEffect,useState} from "react";
import enled from '../images/enled.png';
import "./Hero.css";
import backgroundImage from '../images/dofon.jpg'
import backgroundImage1 from '../images/dofon.jpg'; // Replace with your background image files
import backgroundImage2 from '../images/IMG_9489.jpg';
import backgroundImage3 from '../images/IMG_9497.jpg';


const Hero = () =>{    

    
  return (
    
      <div
        className="flex flex-col items-center justify-center h-screen bg-cover bg-center "
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <img src={enled} alt="Logo" className="w-120 mb-8" /> 
        <h1 className="text-black text-4xl font-bold mb-4">BIENVENIDO</h1>
      </div>
    
  );

}

export default Hero;
{/*<section class="h-screen w-screen p-12">          
<div class="max-w-7xl mx-auto text-center ">  */}

{/*<section className=" banner">
          <div className="banner-content">
            <img src={enled} alt="ENLED" ></img> 
          </div>          
        </section>*/}

 {/*


<div
        className="flex flex-col items-center justify-center h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <img src={enled} alt="Logo" className="w-40 mb-8" /> 
        <h1 className="text-white text-4xl font-bold mb-4">Welcome to Our Website</h1>
        <p className="text-white text-lg">Discover amazing products and services.</p>
      </div>





return(
        
      <section class="h-screen p-12 bg-slate-300">
      <div class="max-w-7xl mx-auto text-center">
        <img src={enled} alt="Site under construction" class="mx-auto"></img> 
        
      </div>
    </section>
    )





import React, { useState, useEffect } from 'react';
import logo from './logo.png'; // Replace with your logo image file
import backgroundImage1 from './background1.jpg'; // Replace with your background image files
import backgroundImage2 from './background2.jpg';
import backgroundImage3 from './background3.jpg';

const HeroPage = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const backgroundImages = [backgroundImage1, backgroundImage2, backgroundImage3];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % backgroundImages.length);
    }, 5000); // Change the duration of each transition in milliseconds (e.g., 5000 = 5 seconds)

    return () => clearInterval(interval);
  }, [backgroundImages.length]);

  return (
    <div
      className="flex flex-col items-center justify-center h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImages[currentImage]})` }}
    >
      <img src={logo} alt="Logo" className="w-40 mb-8" /> 
      <h1 className="text-white text-4xl font-bold mb-4">Welcome to Our Website</h1>
      <p className="text-white text-lg">Discover amazing products and services.</p>
    </div>
  );
};    
*/}      