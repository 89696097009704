import React from "react";
import './Products.css';
import cubo from '../images/cubo.jpeg';

const product = [
  {
    id: 1,
    name: "Product 1",
    image: {cubo},
    price: "$19.99",
  },
  {
    id: 2,
    name: "Product 2",
    image: {cubo},
    price: "$29.99",
  },
  {
    id: 3,
    name: "Product 3",
    image: {cubo},
    price: "$39.99",
  },
  {
    id: 4,
    name: "Product 4",
    image: {cubo},
    price: "$49.99",
  },
];

function Products() {
  return (
    <section class="flex justify-center p-12 bg-lime-300">
        <div className="product-gallery">
        {product.map((p) => (
            <div className="product" key={p.id}>
            <img src={cubo} alt={p.name} />
            <h2>{p.name}</h2>
            <p>{p.price}</p>
            </div>
        ))}
        </div>
    </section>
  );
}

export default Products;